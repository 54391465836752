import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {

    private localStorage: any;
    public Keys = LocalStorageKeys;

    constructor() {
        this.localStorage = window.localStorage;
    }

    save(key: string, object: string) {
        ////console.log(`save in storage key:${key} and object:`, object)
        let stringToSaveToStorage = "";
        if (typeof (object) === "string") {
            stringToSaveToStorage = object;
        } else {
            stringToSaveToStorage = JSON.stringify(object);
        }
        this.localStorage.setItem(key, stringToSaveToStorage);
        let result = null;
        try {
            result = this.getAsObject(key);
        } catch (ex) {
            result = this.get(key);
        }
        return result;
    }

    getAsObject(key: string) {
        var item = this.get(key);

        if (item && item != "") {
            let parsedItem = item;
            try {
                parsedItem = JSON.parse(item);
            } catch (e) {
            }
            return parsedItem;
        }

        return item;
    }

    get(key: string) {
        return this.localStorage.getItem(key);
    }

    delete(key: any) {
        this.localStorage.removeItem(key);
    }
}

export const LocalStorageKeys = {
    Token: "Token",
    RefreshToken: "RefreshToken",
    UserProfile: "UserProfile",
    UserAccess: "UserAccess",
    LoggedUserInfo: "LoggedUserInfo",
    RememberMe: "RememberMe",
    Languages: "Languages",
}
