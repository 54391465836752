import { Component, OnInit } from '@angular/core';
import {StorageService} from '../shared/storage.service';
import {catchError, map} from 'rxjs/operators';
import {pipe, throwError} from 'rxjs';
import {DataService} from '../shared/data.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})

export class PrivacyComponent implements OnInit {
  privacy:any;

  constructor(private dataService: DataService, private storage: StorageService) {
  }

  ngOnInit(): void {
  //   this.dataService.sendGetRequest('Privacy/PrivacyPolicy').subscribe(data => {
  //     console.log(data);
  //     this.privacy = data;
  //   })
   }
}

