import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {RequestOptions} from '@angular/http';
import {StorageService} from './storage.service';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private REST_API_SERVER = "https://api.inmenu.am/api/";

  constructor(private httpClient: HttpClient,private storage:StorageService) { }

  public sendGetRequest(url:string){
    // let header: HttpHeaders = new HttpHeaders();
    // header.set("Content-Type", "application/json");
    // header.set("Accept", "application/json");
    // header.set("Access-Control-Allow-Origin", this.REST_API_SERVER);
    // header.set("lng", this.storage.get(this.storage.Keys.Languages));
    // console.log(header);
    return this.httpClient.get(this.REST_API_SERVER+url,{   headers: {'lng':this.storage.get(this.storage.Keys.Languages)}
    });
  }
}
