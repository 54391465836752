<section>
  <div class="container-page">
    <div class="row mt-5 ">
      <div class="col-lg-12 col-xl-5 image_content1">
        <img src="assets/image/pay.png" class="mobile_image">
      </div>
      <div class="col-lg-12 col-xl-7">
        <div class=" pt-3 mt-5">
          <div class="first_title  pl-3">
            {{'client_title_1'|translate}}
          </div>


          <div class="mt-5" >
            <div class="row">
              <div class="col-lg-4 qr-div">
                <img src="assets/image/qr.png" class="qr">
              </div>
              <div class="col-lg-8">
                <p class="first_text">{{'client_p_1'|translate}}
                <div class="mt-4 market_div">
                <a *ngIf='os!="ANDROID"' href="https://app.inmenu.am/T7OB/de12b18e">
                  <img src="assets/image/app_store.png" class="market mr-2">
                </a>
                  <a *ngIf='os!="IOS"' href="https://app.inmenu.am/T7OB/ec603b8d">
                    <img src="assets/image/play_market.png" class="market ">
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12 col-xl-5 image_content">
        <img src="assets/image/pay.png" class="mobile_image">
      </div>
    </div>
  </div>
</section>
<section>
  <div class="container-page">
    <div class="mt-4 row text-center">
      <div class="col-lg-12 col-xl-4">
        <img src="assets/image/Group1111.png" class="image_1" alt="">
        <p class="p_1">{{'client_p_1_1'|translate}}</p>
        <p class="p_2">{{'client_p_1_1_1' | translate }}</p>
      </div>
      <div class="col-lg-12 col-xl-4">
        <img src="assets/image/Group1044.png" class="image_1" alt="">
        <p class="p_1">{{'client_p_1_2' | translate }}</p>
        <p class="p_2">{{'client_p_1_2_1' | translate }}</p>
      </div>
      <div class="col-lg-12 col-xl-4">
        <img src="assets/image/Group1112.png" class="image_1" alt="">
        <p class="p_1">{{'client_p_1_3' | translate }}</p>
        <p class="p_2">{{'client_p_1_3_1' | translate }}</p>
      </div>
    </div>
  </div>
</section>
<section class="cl_page02">
  <div class="container-page ">
    <div class="row mt-5 bg_color" >

      <div class="col-4 cl4">
        <span class="numbers mr-7">02</span>
        <img src="assets/image/solid_orange.png" class="border_oraneg" >
      </div>
      <div class="col-8 item_center">
        <p class="title_02 title">
          {{'client_title_2' | translate }}
        </p>
      </div>
      <div class="offset-lg-4 col-lg-8 col-t2-p" >
        <p class="description_02 description">
          {{'client_p_2' | translate }}
        </p>
      </div>
    </div>
  </div>
</section>
<section class="cl_page02_1">
  <div class="container-page">
    <div class="row bg_color image_part">
      <div class="col-4 col-xl-4 m-pl-5">
        <img src="assets/image/Group994.png" class="mobile_image mobile-ml-5 ">
      </div>
      <div class="col-4 col-xl-4">
        <img src="assets/image/Group995.png" class="mobile_image mobile-ml-5 for_margin_1">
      </div>
      <div class="col-4 col-xl-4 m-pr-5">
        <img src="assets/image/Group996.png" class="mobile_image m-mr-5 for_margin_2">
      </div>

    </div>
  </div>
</section>
<section class="cl_page03">
  <div class="container-page">
    <div class="row bg_color mt-5" >
      <div class="col-lg-12 col-xl-7">
        <div class="row">
          <div class="col-4 col-xl-12 cl4 number_item_center">
            <span class="numbers mr-18">03</span>
            <img src="assets/image/solid_orange.png" class="border_oraneg">
          </div>
          <div class="col-8 col-xl-12 item_center">
            <div class="title_03 title  pl-3">
              {{'client_title_3' | translate }}
            </div>
          </div>
          <div class="offset-lg-4 offset-xl-0 col-lg-8 col-xl-12 col8-p" >
            <div class="description_03 description" >
              <p class="pl-3 pr-3">
                {{'client_p_3' | translate }}
              </p>
            </div>
          </div>


        </div>
      </div>
      <div class="col-lg-12 col-xl-5 for_center">
        <img src="assets/image/Group1058.png" class="mobile_image">
      </div>
    </div>
  </div>
</section>
<section class="cl_page04">
  <div class="container-page">
    <div class="row mt-5 ">
      <div class="col-lg-12 col-xl-5 for_center image_content">
        <img src="assets/image/Group1060.png" class="mobile_image">
      </div>

      <div class="col-lg-12 col-xl-7">
        <div class="row m-pl-5 margin">
          <div class="col-4 col-xl-12 cl4 number_item_center">
            <img src="assets/image/solid_orange.png" class="border_oraneg">
            <span class="numbers ml-18">04</span>
          </div>
          <div class="col-8 col-xl-12 item_center">
            <div class="title_04 title ">
              {{'client_title_4' | translate }}
            </div>
          </div>
          <div class="offset-lg-4 offset-xl-0 col-lg-8 col-xl-12 col8-p">
            <div class="description_04 description" >
              <p>
                {{'client_p_4' | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12 col-xl-5 for_center image_content1">
        <img src="assets/image/Group1060.png" class="mobile_image">
      </div>

    </div>
  </div>
</section>
<section class="cl_page05">
  <div class="container-page">
    <div class="row  bg_color">
      <div class="col-lg-12 col-xl-7">

        <div class="row margin">
          <div class="col-4 col-xl-12 cl4 number_item_center">
            <img src="assets/image/solid_orange.png" class="border_oraneg float-right">
            <span class="numbers ml-18">05</span>
          </div>
          <div class="col-8 col-xl-12 item_center">
            <div class="title_05 title">
              {{'client_title_5' | translate }}
            </div>
          </div>
          <div class="offset-lg-4 offset-xl-0 col-lg-8 col-xl-12 col8-p">
            <div class="description_05 description" >
              <p >
                {{'client_p_5' | translate }}
              </p>
            </div>
          </div>
        </div>



      </div>
      <div class="col-lg-12 col-xl-5 for_center">
        <img src="assets/image/Group931.png" class="mobile_image">
      </div>
    </div>
  </div>
</section>
<section class="cl_page06">
  <div class="container-page">
    <div class="row mt-5 ">
      <div class="col-lg-12 col-xl-5 for_center image_content">
        <img src="assets/image/Group1159.png" class="mobile_image">
      </div>
      <div class="col-lg-12 col-xl-7">
        <div class="row m-pl-5 margin">
          <div class="col-4 col-xl-12 cl4 number_item_center">
            <img src="assets/image/solid_orange.png" class="border_oraneg float-right">
            <span class="numbers ml-18">06</span>
          </div>
          <div class="col-8 col-xl-12 item_center">
            <div class="title_04 title ">
              {{'client_title_6' | translate }}
            </div>
          </div>
          <div class="offset-lg-4 offset-xl-0 col-lg-8 col-xl-12 col8-p">
            <div class="description_04 description" >
              <ul class="pl-4 description_li">
                <li>{{'client_li_6_1' | translate }}</li>
                <li>{{'client_li_6_2' | translate }}</li>
                <li>{{'client_li_6_3' | translate }}</li>
                <li>{{'client_li_6_4' | translate }}</li>
              </ul>
              <p >
                {{'client_p_6' | translate }}
              </p>
            </div>
            <div class="client_partner_div">
              <a href="https://partner.inmenu.am/?lng={{translate.currentLang}}" class="client_partner">{{'client_partner' | translate }}</a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12 col-xl-5 for_center image_content1">
        <img src="assets/image/Group1159.png" class="mobile_image">
      </div>
    </div>
  </div>
</section>
<section class="section_contact">
  <div class="container-page">
    <div class="row bg_color  last_row">
      <div class="col-xl-12 col-xxl-6">
        <div class="contact margin-5 text-center question">
          <span class="finish pt-5 question_title"><b>{{'have_question' | translate }}    </b></span>
          <span class="speak question_title">{{'talk' | translate }}</span>
          <div class="p-5">
            <form method="post" action="mail.php" class="mb-5 forma">
              <div class="form-group">
                <input type="text" name="name" placeholder="{{'name' | translate }}" class="input " required>
              </div>
              <div class="form-group">
                <input type="email" name="email" placeholder="{{'email' | translate }}" class="input" required>
              </div>
              <div class="form-group">
                <input type="text" name="question" placeholder="{{'question' | translate }}" class="input input-p2" required>
              </div>
              <div class="form-group">
                <button class="client-submit-button">{{'submit' | translate }}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-xl-12 col-xxl-6 feed">
        <img src="assets/image/solid_orange.png" class="float-left">
        <div class="ml-7">
          <p class="feedback">{{'feedback' | translate }}</p>
          <span class="phone">{{'phone' | translate }} 055-46-63-68</span>
          <p class="infoMail">info@inmenu.am</p>
          <div>
            <a target="_blank" href="https://www.facebook.com/InMenuArmenia"><img src="assets/image/facebook.png" alt=""></a>
            <a target="_blank" href="https://www.youtube.com/channel/UCnJ-EFoMn412MnjNxAEAtzA" class="ml-1"><img src="assets/image/youtube.png" alt=""></a>
            <a target="_blank" href="https://www.instagram.com/Inmenuarmenia/" class="ml-1"><img src="assets/image/instagram.png" alt=""></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
