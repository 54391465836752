import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(public translate:TranslateService) { }

  os:string="";
  ngOnInit(): void {
    var userAgent = navigator.userAgent || navigator.vendor || window.navigator.userAgent;
    if (/android/i.test(userAgent)) {
      this.os="ANDROID";
    }
    else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      this.os="IOS";
    }
  }
}
