import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Route, Router} from '@angular/router';
import {StorageService} from './shared/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(public translate: TranslateService, public router: Router,public activeRoute:ActivatedRoute,private storage:StorageService) {
    translate.setDefaultLang('en');
    this.activeRoute.queryParams.subscribe(params => {
      translate.use(params.lng||this.storage.get(this.storage.Keys.Languages)||translate.getBrowserLang() || translate.getDefaultLang());
    });

  }

  title = 'inmenu';


  useLanguage(language: string) {
    this.translate.use(language);
    this.storage.save(this.storage.Keys.Languages,language);
    window.location.reload();
  }

}
